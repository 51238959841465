import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
import style from './TwoColsMedia.module.scss';

import { useDispatch } from 'react-redux';

import { openPlayerModal, updatePlayerLink, updatePlayerPoster } from '../../redux/modal/playerModal.action';

const TwoColsMedia = props => {
    const { content } = props;
    const dispatch = useDispatch();

    const openPlayer = (link, poster) => {
        dispatch(openPlayerModal());
        dispatch(updatePlayerLink(link));
        dispatch(updatePlayerPoster(poster));
    };

    const isDesktop = useMediaQuery('only screen and (min-width: 650px)');

    return (
        <div className={[style.el, 'two-cols-media'].join(' ')}>
            {content &&
                content.map((item, i) => {
                    return (
                        <div
                            className={[
                                style.container,
                                'container',
                                item.isMediaLeft ? style.isMediaLeft : null,
                                item.quote ? style.quoteContainer : null,
                                content.length === 1 ? style.onlyOne : null,
                            ].join(' ')}
                            key={i}
                        >
                            <div className={style.media}>
                                {item.image && !item.video && (
                                    <div
                                        className={style.banner}
                                        style={{
                                            backgroundImage: `url(${
                                                isDesktop
                                                    ? item.image.url
                                                    : item.mobileImage?.url.legnth > 0
                                                    ? item.mobileImage?.url
                                                    : item.image.url
                                            })`,
                                            backgroundSize: 'cover',
                                        }}
                                    />
                                )}
                                {item.video && (
                                    <div className={style.player}>
                                        <div
                                            className={style.banner}
                                            style={{
                                                backgroundImage: `url(${
                                                    isDesktop
                                                        ? item.image.url
                                                        : item.mobileImage?.url.legnth > 0
                                                        ? item.mobileImage?.url
                                                        : item.image.url
                                                })`,
                                                backgroundSize: 'cover',
                                            }}
                                        />
                                        <div
                                            className={style.playButton}
                                            onClick={() => openPlayer(item.video, item.poster)}
                                        >
                                            <img src='/images/people/play.png' />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                className={[style.text, item.video || item.vimeoId ? style.videoText : null].join(' ')}
                            >
                                {item?.title && (
                                    <h2
                                        className={style.title}
                                        dangerouslySetInnerHTML={{
                                            __html: item.title,
                                        }}
                                    />
                                )}
                                {item?.description && (
                                    <div
                                        className={style.desc}
                                        dangerouslySetInnerHTML={{
                                            __html: item.description,
                                        }}
                                    />
                                )}
                                {item?.quote && (
                                    <div className={style.quote}>
                                        <img className={style.icon} src='/images/people/quote.svg' />
                                        <div
                                            className={['h2', style.headline].join(' ')}
                                            dangerouslySetInnerHTML={{
                                                __html: item.quote.headline,
                                            }}
                                        />
                                        <p
                                            className={style.name}
                                            dangerouslySetInnerHTML={{
                                                __html: item.quote.name,
                                            }}
                                        />
                                        <p
                                            className={style.job}
                                            dangerouslySetInnerHTML={{
                                                __html: item.quote.title,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

TwoColsMedia.propTypes = {
    content: PropTypes.array,
};
export default TwoColsMedia;

TwoColsMedia.defaultProps = {};
